<script context="module">
  export const navList = [
    {
      name: "StartPage",
      title: "Homepage",
      icon: homeIcon,
    },
    {
      name: "AboutPage",
      title: "About me",
      icon: manIcon,
    },
    {
      name: "SkillsPage",
      title: "Skills",
      icon: checklistIcon,
    },
    {
      name: "ExperiencePage",
      title: "Experience",
      icon: resumeIcom,
    },
    {
      name: "ContactPage",
      title: "Contact",
      icon: envelopeIcon,
    },
  ];
</script>

<script>
  import {
    envelopeIcon,
    resumeIcom,
    homeIcon,
    manIcon,
    checklistIcon,
  } from "../icons/Icons.svelte";
</script>
