<script context="module">
  export const jobsList = [
    {
      name: "Divante Sp. z o.o.",
      title: "Frontend Developer",
      description:
        `Developer tasks:<br/>
        - Magento (Frontend part development) <br/>
        - Shopware (Frontend part development) <br/>
        - Shopware-PWA (I was a part of the core team) <br/>
        - Other frontend tasks <br/>

        <br/><br/>

        Leader tasks:<br/>
        - Feedbacks for Frontend Devs <br/>
        - Help developers with everyday problems (communication, hardware/software etc.) <br/>
        - Project valuations <br/>
        - Job interviews conduction <br/>
        - New persons develop <br/>
        - New persons mentor`,
      technologies: ["js", "html", "css", "magento", "jquery", "react", "vue.js", "rwd"],
      clients: [
        "Internal",
        "Enea",
        "Enea - Strefa Zakupów",
        "Open Loyalty",
        "Carrefour",
        "Energa",
        "Distripark",
        "Ikaurs",
        "Yope"
      ],
      startDate: "03-2018",
      endDate: "",
      opinion: false,
    },
    {
      name: "Personal Trainer M.B.",
      title: "Freelance / Graphic Designer",
      description: "I've designed leaflets for Michał B. - personal trainer.",
      technologies: ["affinity designer"],
      clients: ["Owner"],
      startDate: "03-2019",
      endDate: "03-2019",
      opinion: false,
    },
    {
      name: "Usługi Transportowe K.P.",
      title: "Freelance / Frontend",
      description:
        "Another successful SPA webpage and one of my best clients. Unfortunately, Mr. Krzysztof closed his firm but cooperation was great!",
      technologies: ["html", "scss", "js"],
      clients: ["Owner"],
      startDate: "01-2019",
      endDate: "05-2019",
      opinion: false,
    },
    {
      name: "BusWojtex",
      title: "Freelance / Frontend",
      description:
        "I did a SPA business-card webpage for a transport company to see how vue.js works - it's cool.",
      technologies: ["vue.js", "sass", "html"],
      clients: ["Owner"],
      startDate: "10-2018",
      endDate: "03-2019",
      opinion: false,
    },
    {
      name: "Moni - Usługi Krawieckie",
      title: "Freelance / Frontend",
      description:
        "I've designed a brand logo in Adobe Illustrator, created a simple SPA web page, started a fan page on Facebook",
      technologies: ["illustrator", "html", "sass", "js"],
      clients: ["Owner"],
      startDate: "03-2018",
      endDate: "08-2018",
      opinion: false,
    },
    {
      name: "panaceum.it",
      title: "CEO",
      description: "I've found my own (one-man army) firm!",
      technologies: ["js", "svelte", "scss", "html"],
      clients: [
        "Own Portfolio",
        "Divante",
        "Buswojtex",
        "Moni",
        "Posadzki Ostrowski",
        "Piana Dachy",
        "Washing Machine",
      ],
      startDate: "03-2018",
      endDate: "",
      opinion: false,
    },
    {
      name: "Stark Industries",
      title: "Jarvis Friend",
      description: "Nope. That's a joke! Sorry :)",
      technologies: ["AI"],
      clients: ["Avengers"],
      startDate: "01-2018",
      endDate: "03-2018",
      opinion: false,
    },
    {
      name: "Mobile Vikings",
      title: "Freelance / Frontend",
      description:
        "As a subcontractor, I was responsible for implementing the new version of Mobile Vikings Webpage.",
      technologies: ["jquery", "html", "sass", "git"],
      clients: ["Washing Machine"],
      startDate: "04-2017",
      endDate: "08-2017",
      opinion: false,
    },
    {
      name: "Lemon Sky J. Walter Thompson Poland",
      title: "Frontend Developer",
      description:
        "I was part of Frontend team. Great persons I gained a lot of experience working with them. Most of the time I was making contest pages, integrations with backend (django). Developing RWD in existing / new projects. Finding a solution for business problems f.e. limited page size or loading time. I've worked remotely occasionally.",
      technologies: [
        "js",
        "html",
        "sass",
        "jquery",
        "react",
        "django",
        "emails",
      ],
      clients: [
        "Internal",
        "Winiary",
        "Nowy Styl Group",
        "Wedel",
        "PFR",
        "Prudential",
        "Mazda",
        "Tymbark",
        "Kubuś",
        "Shell",
        "Tiger",
        "Volkswagen",
        "Bols",
      ],
      startDate: "10-2015",
      endDate: "02-2018",
      opinion: true,
    },
    {
      name: "Red Dragon Media Sp. z o.o.",
      title: "Junior Frontend Developer",
      description:
        "Cutting from project and coding page layout while maintaining certain standards. Implementing mobile versions to existing / new websites. Making animations, using a lot of js libraries for slides, making navigations etc.",
      technologies: ["js", "html", "css", "sass", "jquery"],
      clients: [
        "Tauron",
        "Cooklet",
        "Skyr",
        "Kuchnia Marche",
        "PZU",
        "Danone",
        "emails",
      ],
      startDate: "02-2015",
      endDate: "10-2015",
      opinion: false,
    },
    {
      name: "WUL-KAM K.W.",
      title: "Freelance / Graphic Designer",
      description:
        "I've designed visit cards for the best vulcanization firm near Legnica",
      technologies: ["Illustrator", "Photoshop"],
      clients: ["Owner"],
      startDate: "01-2015",
      endDate: "02-2015",
      opinion: false,
    },
    {
      name: "WEBDEVPLUS",
      title: "Freelance / Frontend",
      description: "I was part of .",
      technologies: ["js", "html", "css", "jquery", "bootstrap"],
      clients: ["Owner"],
      startDate: "03-2014",
      endDate: "03-2015",
      opinion: false,
    },
    {
      name: "Freelancer.com",
      title: "Freelance / Frontend",
      description:
        'Implementation of the "Giggzu" website from scratch based on the Photoshop project. I"ve used basic frontend stack - HTML, CSS, Bootstrap 2.',
      technologies: ["js", "html", "css", "bootstrap"],
      clients: ["Giggzu owner"],
      startDate: "10-2014",
      endDate: "11-2014",
      opinion: false,
    },
    {
      name: "Sygnity S.A.",
      title: "Software Tester",
      description:
        "My second practice. I was responsible for manual and functional testing without software.",
      technologies: [""],
      clients: ["Internal"],
      startDate: "12-2013",
      endDate: "02-2014",
      opinion: false,
    },
    {
      name: "Next Computers s.c.",
      title: "Computer Specialist",
      description:
        "My practice job on studies. Contact with the client, consulting, sales service, and accepting service orders.",
      technologies: [],
      clients: [],
      startDate: "04-2013",
      endDate: "07-2013",
      opinion: false,
    },
  ];
</script>
