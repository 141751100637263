<script context="module">
  export const iconsList = [
    {
      description: "email",
      icon: emailIcon,
    },
    {
      description: "phone",
      icon: phoneIcon,
    },
    {
      description: "github",
      icon: githubIcon,
    },
    {
      description: "linkedin",
      icon: linkedinIcon,
    },
    {
      description: "messanger",
      icon: messangerIcon,
    },
    {
      description: "donate",
      icon: bitcoinIcon,
    },
  ];
</script>

<script>
  import {
    bitcoinIcon,
    emailIcon,
    phoneIcon,
    linkedinIcon,
    messangerIcon,
    githubIcon,
  } from "../../icons/Icons.svelte";
</script>
